import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import _, { kebabCase } from "lodash";

// import * as styles from "./Album.module.scss";


const toMinutes = function(t) {
  var m, min, r;
  m = 60;
  r = t % m;
  min = (t - r) / m;
  return min + ':' + pad(r);
};

const pad = function(n) {
  return ('00' + n).slice(-2);
};

// https://developer.spotify.com/dashboard/20e560ff4ee947d5b5016fd52b0c7850/settings
// https://developer.spotify.com/documentation/web-api/tutorials/getting-started
const accessToken = `BQCBodYTJQ4beO8Z-DK250n2zCezJokp6BXHuJK28GfDVeLg9TIQHl6q-WOssQaruURUivBlrA2mlxcmne1PBOeDHj51AAd5__MS_xgxtF51geuj89c`

async function getAlbum(id) {
  const album = `https://api.spotify.com/v1/albums/${id}?market=GB`;

  const response = await fetch(album, {
    headers: {
      Authorization: 'Bearer ' + accessToken
    }
  });

  const data = await response.json();

  console.log("album", data);
  return data;
}




const Album = ({ className, data }) => {
  const [albums, setAlbums] = useState();
  // const image = getImage(data.cover);
  const [theAlbum, setTheAlbum] = useState();
  const [number, setNumber] = useState(0);
  const [spotify, setSpotify] = useState("");
  const [dataType, setDataType] = useState("");

  const getSpotify = (evt) => {
    evt.preventDefault();
    // https://open.spotify.com/album/0XwEfJMejqTpgalJwtp1CM?si=go9_IkE2Q5SD1cMd7VT7CA

    const stuff = getAlbum(spotify.match(/([a-zA-Z0-9]{22})/)[0]);
    stuff.then((spo) => {
      setTheAlbum({
        label: spo.label,
        id: spo.id,
        year: spo.release_date,
        tracks: spo.tracks.items.map(t => ({
          number: t.track_number,
          name: t.name,
          duration: toMinutes(Math.round(t.duration_ms / 1000)),
        })),
      });
    });
  };

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/albums.json");
      const albums = await response.json();
      setAlbums(albums);
    }
    fetchData();
  }, []);

  // Start with latest album.
  useEffect(() => {
    albums && setNumber(albums.length)
  }, [albums]);

  return (
    <div className={'styles.el'}>
      <Link to="/stats">Stats</Link>
      <form onSubmit={getSpotify}>
        <fieldset>
        <label>Spotify </label>
        <input type="text" value={spotify} onChange={e => setSpotify(e.target.value)} />
        {" "}
        <button>Get</button>
{/* {"  "}label: {theAlbum.label} */}
{theAlbum && <pre>
{"  "}spotify: {theAlbum.id}
<br />  year: {theAlbum.year.slice(0,4)}
<br />  release_date: {theAlbum.year}
<br />  tracks:
{theAlbum.tracks.map(t => (<>
<br />  - number: {t.number}
<br />{"    "}name: {t.name}
<br />{"    "}duration: {t.duration}
</>))}
</pre>}
        </fieldset>
      </form>
      <br />
      <fieldset>
        <label>Album </label>
        <input type="number" value={number} onChange={e => setNumber(e.target.value)} />
      </fieldset>

      <div>
        <a onClick={() => setDataType("tags")}>tags</a>
        {" • "}
        <a onClick={() => setDataType("links")}>links</a>
        {" • "}
        <a onClick={() => setDataType("release_date")}>release_date</a>
        {" • "}
        <a onClick={() => setDataType("year")}>year</a>
      </div>
      <p>Albums missing {dataType}: {_.filter(albums, {[dataType]: null}).length}</p>
      {number && <>

        {" "}jump to next (<a href="#" style={{color:"blue",textDecoration:"underline"}} onClick={() => setNumber(_.filter(albums, {[dataType]: null})[0].number)}>
          {_.filter(albums, {[dataType]: null}).length
            && _.filter(albums, {[dataType]: null})[0].number}
        </a>)
      </>}

      {number && <pre style={{fontFamily:'monospace'}}>{JSON.stringify(_.filter(albums, {"number": parseInt(number,10)}), null, 2)}</pre>}
      {/* {albums.filtermaps()} */}
      {/* <Link to={"/" + kebabCase(`${data.number}-${data.title}`)}>
        {image ?
          <GatsbyImage image={image} alt="" className={'styles.cover'} />
        :
          <img src={data.cover_external} alt="" className={'styles.cover'} />
        }
      </Link>
      <h2 className={'styles.title'}>
        <Link to={"/" + kebabCase(`${data.number}-${data.title}`)}>
          {data.title}
        </Link>
      </h2>
      <p className={'styles.artist'}>by {data.artist}</p> */}
    </div>
  );
};

Album.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default Album;
